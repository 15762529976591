import rulesMixin from '@/mixins/rulesMixin'
import modelMixin from '@/mixins/modelMixin'

const formMixin = {
  mixins: [modelMixin, rulesMixin],
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      form: false
    }
  }
}

export default formMixin
