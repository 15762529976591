<template>
  <v-card
    flat
  >
    <v-toolbar
      class="mt-2"
      dense
      flat
    >
      <v-btn
        icon
        @click="$router.go(-1)"
      >
        <v-icon dense>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-breadcrumbs :items="breadcrumbs" />
      <v-spacer />
      <slot
        name="actions"
        :form="form"
        :fields="fields"
        :isEqual="isEqual"
      />
    </v-toolbar>
    <v-card-text>
      <v-form v-model="form">
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              v-model="fields.name"
              :rules="[rules.required]"
              label="Name"
              filled
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="5">
            <v-text-field
              v-model="fields.street"
              :rules="[rules.required]"
              label="Street"
              filled
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-text-field
              v-model="fields.city"
              :rules="[rules.required]"
              label="City"
              filled
              outlined
              dense
            />
          </v-col>
          <v-col cols="auto">
            <v-text-field
              v-model="fields.zipCode"
              :rules="[rules.required]"
              label="Zip Code"
              filled
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-text-field
              v-model="fields.country"
              :rules="[rules.required]"
              label="Country"
              filled
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-text-field
              v-model="fields.legalId"
              :rules="[rules.required]"
              label="Legal Identifier"
              hint="SIREN or SIRET or DUNS"
              filled
              outlined
              dense
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import formMixin from '@/mixins/formMixin'

export default {
  name: 'EstablishmentForm',
  mixins: [formMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
