const rulesMixin = {
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        isInteger: value => Number.isInteger(value) || 'Must be whole number.',
        isPositive: value => value > 0 || 'Must be greater than 0',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        isExactString: (string, message = 'Not equal') => (value) => string === value || message
      }
    }
  }
}

export default rulesMixin
